import React from 'react';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';
import loadable from '@loadable/component';

let Lottie;

class Animation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
      isStopped: true,
      isPaused: true,
    };

    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onRefChange = this.onRefChange.bind(this);
  }

  componentDidMount() {
    Lottie = loadable(() => import('react-lottie-segments'));
  }

  onRefChange(node) {
    new Function('values', this.props.attributes.onMount)({ ref: node });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      this.setState({ isStopped: true, isPaused: true });

      if (this.props.isActive) {
        this.setState({ isStopped: false, isPaused: false });
      }
    }
  }

  onEnter() {
    // On first enter, have load start animation, unpause on subsequent enters
    if (!this.state.entered) {
      this.setState({ entered: true });
    } else {
      this.setState({ isPaused: false });
    }
  }

  onLeave() {
    this.setState({ isPaused: true });
  }

  onStart(e) {
    const { delay, dontPlayOnEnter } = this.props.attributes;
    if (dontPlayOnEnter) {
      return;
    }

    const that = this;
    const timeToVideo = delay && !this.state.showVideo ? 3000 : 0;

    if (!this.props.isToggled || this.props.isActive) {
      if (!window.isVRTest) {
        setTimeout(() => {
          that.setState({ showVideo: true }, () => {
            this.setState({ isStopped: false, isPaused: false });
          });
        }, timeToVideo);
      }
    }
  }

  render() {
    const { attributes, onComplete } = this.props;
    const {
      className,
      url,
      opts,
      bottomOffset,
      delayOnEnd,
      renderImmediately,
    } = attributes;
    const { loop, segments, rendererSettings } = opts || {};

    const options = {
      renderer: 'svg',
      loop: loop ? JSON.parse(loop) : false,
      autoplay: false,
      path: url,
      rendererSettings: rendererSettings ? JSON.parse(rendererSettings) : null,
    };

    if (segments) {
      var sequence = {
        segments: JSON.parse(segments),
        forceFlag: true,
      };
    }

    const eventListeners = [
      {
        eventName: 'DOMLoaded',
        callback: (e) => this.onStart(e),
      },
    ];

    // Stop video on last frame if not looped
    // if (!loop) {
    //   eventListeners.push({
    //     eventName: 'enterFrame',
    //     callback: (animation) => {
    //       if (this.el && animation.currentTime > (this.el.totalFrames - 1)) {
    //         this.el.pause();
    //      }
    //     }
    //   })
    // }

    if (onComplete) {
      eventListeners.push({
        eventName: 'complete',
        callback: () => {
          onComplete(this.props.activeIndex);
        },
      });
    }

    if (delayOnEnd) {
      eventListeners.push({
        eventName: 'complete',
        callback: () => {
          setTimeout(() => {
            this.el?.goToAndPlay(0, true);
          }, parseInt(delayOnEnd));
        },
      });
    }

    const animationJSX = (
      <div className={`${className}`} data-lottie>
        {Lottie ? (
          <Lottie
            ref={this.onRefChange}
            options={options}
            playSegments={sequence || undefined}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
            eventListeners={eventListeners}
          />
        ) : undefined}
      </div>
    );

    return renderImmediately ? (
      animationJSX
    ) : (
      <Waypoint
        onEnter={this.onEnter}
        onLeave={this.onLeave}
        bottomOffset={bottomOffset || '-20%'}
      >
        {this.state.entered ? animationJSX : undefined}
      </Waypoint>
    );
  }
}

export default Animation;
